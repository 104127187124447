<template>
  <div>
    <Chart :options="options" />
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
export default {
  props: {
    timeTopTen: {
      type: Array,
      default: () => [],
    },
    categoriesTopTen: {
      type: Array,
      default: () => [],
    },
    priceTopTen: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Chart,
  },
  computed: {
    options() {
      return {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: {
          categories: [...this.categoriesTopTen],
          title: {
            text: null,
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "ยอดขาย (บาท)",
            align: "high",
          },
          labels: {
            overflow: "justify",
          },
        },
        tooltip: {
          valueSuffix: " บาท",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        legend: {
          layout: "vertical",
          align: "right",
          verticalAlign: "top",
          x: -40,
          y: -10,
          floating: true,
          borderWidth: 1,
          backgroundColor: "#FFFFFF",
          shadow: true,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "ยอดขาย",
            data: [...this.priceTopTen],
          },
        ],
      };
    },
  },
};
</script>
