<template>
  <div>
    <v-container>
      <v-row class="mt-2">
        <v-col cols="12" class="py-0">
          <h2 class="text-secondary header">รายงานสรุป</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="mb-6 mt-8">
      <v-col cols="12">
        <v-select
          @change="getAll()"
          :items="branchItems"
          item-text="text"
          item-value="value"
          outlined
          v-model="branch"
          label="สาขา"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-card color="mt-5" outlined>
          <v-card-title>
            <div class="d-flex justify-space-between w-100">
              <div>ยอดขายสูงสุด - ต่ำสุด</div>
              <v-btn-toggle>
                <!-- <v-btn small> Day </v-btn>
                <v-btn small> Month </v-btn> -->
              </v-btn-toggle>
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="px-6">
              <v-col cols="6">
                <v-card elevation="4">
                  <v-card-text>
                    <div class="d-flex justify-space-between">
                      <div>ยอดขายสูงสุด</div>
                    </div>
                    <div class="d-flex justify-space-between my-2">
                      <div>วันที่</div>
                      <div v-if="highestPrice.schedule">
                        {{ formatDate(highestPrice.createdOn) }}
                      </div>
                      <div v-else>{{ formatDate(new Date()) }}</div>
                    </div>
                    <div class="d-flex justify-space-between my-2">
                      <div>ราคา</div>
                      <div v-if="highestPrice.price">
                        {{ formatCurrency(highestPrice.price.summary) }} บาท
                      </div>
                      <div v-else>-</div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card elevation="4">
                  <v-card-text>
                    <div class="d-flex justify-space-between">
                      <div>ยอดขายต่ำสุด</div>
                    </div>
                    <div class="d-flex justify-space-between my-2">
                      <div>วันที่</div>
                      <div v-if="lowestPrice.schedule">
                        {{ formatDate(lowestPrice.createdOn) }}
                      </div>
                      <div v-else>{{ formatDate(new Date()) }}</div>
                    </div>
                    <div class="d-flex justify-space-between my-2">
                      <div>ราคา</div>
                      <div v-if="lowestPrice.price">
                        {{ formatCurrency(lowestPrice.price.summary) }} บาท
                      </div>
                      <div v-else>-</div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end my-6 mr-5">
              <JsonCSV
                :data="mostLowest.data"
                :labels="mostLowest.labels"
                name="order-report.csv"
              >
                <v-btn class="font-weight-bold" dark color="green"
                  ><v-icon>mdi-file-export</v-icon>ส่งออก CSV</v-btn
                >
              </JsonCSV>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-container>
        <v-card>
          <v-card-title> รายงานขายต่อรอบกิจกรรม </v-card-title>
          <v-row>
            <v-col class="px-7" cols="6">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatDatePicker(scheduleDate)"
                    label="วันที่"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="th"
                  v-model="scheduleDate"
                  @input="getScheduleReport($event)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <template>
                <v-data-table
                  no-data-text="ไม่พบข้อมูล"
                  :footer-props="footer"
                  :headers="scheduleTable.headers"
                  :items="scheduleTable.items"
                  :items-per-page="5"
                >
                  <template v-slot:item.type="{ item }">
                    {{ item.type | bookingType }}
                  </template>
                  <template v-slot:item.startEnd="{ item }">
                    {{ formatSchedule(item.startTime, item.endTime) }}
                  </template>
                </v-data-table>
              </template>
              <div class="d-flex justify-end my-6 mr-5">
                <JsonCSV
                  :data="scheduleTable.data"
                  :labels="scheduleTable.labels"
                  name="order-report.csv"
                >
                  <v-btn class="font-weight-bold" dark color="green"
                    ><v-icon>mdi-file-export</v-icon>ส่งออก CSV</v-btn
                  >
                </JsonCSV>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
      <v-col cols="12">
        <v-card color="mt-5" outlined>
          <v-card-title>
            <div class="d-flex justify-space-between w-100">
              <div>ยอดผู้เข้าร่วมแบ่งตามประเภทอายุ</div>
              <v-btn-toggle>
                <!-- <v-btn small> Day </v-btn>
                <v-btn small> Month </v-btn> -->
              </v-btn-toggle>
            </div>
          </v-card-title>
          <v-card-text>
            <ChartAgeReport
              :student="student"
              :senior="senior"
              :adult="adult"
              :young="young"
            />
          </v-card-text>
          <v-row class="d-flex justify-end my-6 mr-5">
            <JsonCSV
              :data="ageRange.data"
              :labels="ageRange.labels"
              name="order-report.csv"
            >
              <v-btn class="font-weight-bold" dark color="green"
                ><v-icon>mdi-file-export</v-icon>ส่งออก CSV</v-btn
              >
            </JsonCSV>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card color="mt-5" outlined>
          <v-card-title>
            <div class="d-flex justify-space-between w-100">
              <div>10 อันดับขายดี</div>
              <v-btn-toggle>
                <!-- <v-btn small> Day </v-btn>
                <v-btn small> Month </v-btn> -->
              </v-btn-toggle>
            </div>
          </v-card-title>
          <v-card-text>
            <ChartColumnTenReport
              :categoriesTopTen="categoriesTopTen"
              :priceTopTen="priceTopTen"
              :timeTopTen="timeTopTen"
            />
          </v-card-text>
          <v-row class="d-flex justify-end my-6 mr-5">
            <JsonCSV
              :data="topTen.data"
              :labels="topTen.labels"
              name="order-report.csv"
            >
              <v-btn class="font-weight-bold" dark color="green"
                ><v-icon>mdi-file-export</v-icon>ส่งออก CSV</v-btn
              >
            </JsonCSV>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import JsonCSV from "vue-json-csv";
import dayjs from "dayjs";
import ChartAgeReport from "@/components/Report/ChartAgeReport";
import ChartColumnTenReport from "@/components/Report/ChartColumnTenReport";
import axios from "../../utils/axios";
export default {
  components: {
    ChartAgeReport,
    ChartColumnTenReport,
    JsonCSV,
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),
    footer() {
      return {
        "items-per-page-text": "รายการต่อหน้า",
        "items-per-page-all-text": "ทั้งหมด",
      };
    },
    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
  },
  async mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.branch = branch;
    await this.getScheduleReport();
    await this.getOrder();
    await this.getTopTenOrder();
    await this.getMostLowest();
  },
  data() {
    return {
      young: [],
      student: [],
      adult: [],
      senior: [],
      branch: "",
      menu1: false,
      menu2: false,
      scheduleDate: dayjs().format("YYYY-MM-DD"),
      scheduleTable: {
        headers: [
          { text: "รอบ", value: "startEnd" },
          { text: "ประเภท", value: "type" },
          { text: "จำนวนที่เปิด", value: "max" },
          // { text: "ผู้เข้าร่วม (ผู้ใหญ่)", value: "adult" },
          // { text: "ผู้เข้าร่วม (เด็ก)", value: "young" },
          { text: "จำนวนคนที่เข้าร่วม", value: "joinQty" },
        ],
        items: [],
        data: [],
        labels: [],
      },
      mostLowest: {
        data: [],
        labels: [],
      },
      topTen: {
        data: [],
        labels: [],
      },
      ageRange: {
        data: [],
        labels: [],
      },
      lowestPrice: {},
      highestPrice: {},
      categoriesTopTen: [],
      priceTopTen: [],
      timeTopTen: [],
    };
  },
  methods: {
    formatDatePicker(date) {
      if (date) {
        return dayjs(date).format("D MMMM BBBB");
      }
      return "";
    },
    async getAll() {
      this.young = [];
      this.student = [];
      this.adult = [];
      this.senior = [];
      this.scheduleTable = {
        headers: [
          { text: "รอบ", value: "startEnd" },
          { text: "ประเภท", value: "type" },
          { text: "จำนวนที่เปิด", value: "max" },
          // { text: "ผู้เข้าร่วม (ผู้ใหญ่)", value: "adult" },
          // { text: "ผู้เข้าร่วม (เด็ก)", value: "young" },
          { text: "จำนวนคนที่เข้าร่วม", value: "joinQty" },
        ],
        items: [],
        data: [],
        labels: [],
      };
      this.topTen = {
        data: [],
        labels: [],
      };
      this.ageRange = {
        data: [],
        labels: [],
      };
      this.lowestPrice = {};
      this.highestPrice = {};
      this.categoriesTopTen = [];
      this.priceTopTen = [];
      this.timeTopTen = [];
      await this.getScheduleReport();
      await this.getOrder();
      await this.getTopTenOrder();
      await this.getMostLowest();
    },
    async getScheduleReport(event) {
      try {
        let query;
        if (event) {
          query = event;
        } else {
          query = this.scheduleDate;
        }
        const {
          data: { data },
        } = await axios.get(
          `/report/schedule/round?date=${query}&branch=${this.branch}`
        );
        this.scheduleTable.labels = {
          startEnd: "รอบ",
          type: "ประเภท",
          max: "จำนวนที่เปิด",
          // adult: "จำนวนผู้เข้าร่วม (ผู้ใหญ่)",
          // young: "จำนวนผู้เข้าร่วม (เด็ก)",
          joinQty: "จำนวนคนที่เข้าร่วม",
        };
        for (let i = 0; i < data.length; i++) {
          this.scheduleTable.data.push({
            startEnd: data[i].startTime + " - " + data[i].endTime,
            type:
              data[i].type === "normal"
                ? "ปกติ"
                : data[i].type === "event"
                ? "กิจกรรม"
                : "กลุ่ม",
            max: data[i].max,
            // adult: data[i].adult,
            // young: data[i].young,
            joinQty: data[i].joinQty,
          });
        }
        this.scheduleTable.items = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getOrder() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `/report/age-group?filter=all&branch=${this.branch}`
        );
        this.ageRange.labels = {
          month: "เดือน",
          young: "เด็ก",
          student: "นักเรียน / นักศึกษา",
          adult: "ผู้ใหญ่",
          senior: "ผู้สูงอายุ",
        };
        for (let i = 1; i <= 12; i++) {
          const doc = data.filter((item) => item.month == i);
          if (doc.length > 0) {
            const young = doc.filter((item) => item.ageGroup === "young");
            const student = doc.filter((item) => item.ageGroup === "student");
            const adult = doc.filter((item) => item.ageGroup === "adult");
            const senior = doc.filter((item) => item.ageGroup === "senior");
            this.young.push(young.length);
            this.student.push(student.length);
            this.adult.push(adult.length);
            this.senior.push(senior.length);
            this.ageRange.data.push({
              month: i,
              young: young.length,
              student: student.length,
              adult: adult.length,
              senior: senior.length,
            });
          } else {
            this.ageRange.data.push({
              month: i,
              young: 0,
              student: 0,
              adult: 0,
              senior: 0,
            });
            this.young.push(0);
            this.student.push(0);
            this.adult.push(0);
            this.senior.push(0);
          }
        }

        // console.log(this.adult, this.young);
      } catch (error) {
        console.error(error);
      }
    },
    async getTopTenOrder() {
      try {
        const {
          data: { data },
        } = await axios.get(`/report/top-ten?branch=${this.branch}`);
        this.topTen.labels = {
          orderId: "หมายเลขออเดอร์",
          createdAt: "วันที่ทำรายการ",
          joinedAt: "วันที่เข้าร่วม",
          joinQty: "จำนวนผู้เข้าร่วม",
          priceAdult: "ราคารวม (ผู้ใหญ่)",
          priceYoung: "ราคารวม (เด็ก)",
          summary: "รวมทั้งสิ้น",
        };
        for (let i = 0; i < data.length; i++) {
          const { orderId, price } = data[i];
          const payload = {
            orderId,
            createdAt: dayjs(data[i].createdOn).format("DD/MM/YYYY"),
            joinedAt: dayjs(data[i].schedule.date).format("DD/MM/YYYY"),
            joinQty: data[i].joinQty,
            priceAdult: data[i].price.totalPriceAdult,
            priceYoung: data[i].price.totalPriceYoung,
            summary: price.summary,
          };
          this.topTen.data.push(payload);
          this.categoriesTopTen.push(data[i].schedule.date);
          this.priceTopTen.push(data[i].price.summary);
          this.timeTopTen.push({
            name: data[i].schedule.date,
            data: [`${data[i].price.summary}`],
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getMostLowest() {
      try {
        const arr = [];
        const {
          data: { data },
        } = await axios.get(`/report/most-lowest?branch=${this.branch}`);
        this.mostLowest.labels = {
          orderId: "หมายเลขออเดอร์",
          createdAt: "วันที่ทำรายการ",
          joinedAt: "วันที่เข้าร่วม",
          joinQty: "จำนวนผู้เข้าร่วม",
          priceAdult: "ราคารวม (ผู้ใหญ่)",
          priceYoung: "ราคารวม (เด็ก)",
          summary: "รวมทั้งสิ้น",
        };
        arr.push(data.most);
        arr.push(data.lowest);
        for (let i = 0; i < arr.length; i++) {
          const { orderId, price } = arr[i];
          const payload = {
            orderId,
            createdAt: dayjs(arr[i].createdOn).format("DD/MM/YYYY"),
            joinedAt: dayjs(arr[i].schedule.date).format("DD/MM/YYYY"),
            joinQty: arr[i].joinQty,
            priceAdult: arr[i].price.totalPriceAdult,
            priceYoung: arr[i].price.totalPriceYoung,
            summary: price.summary,
          };
          this.mostLowest.data.push(payload);
        }
        this.lowestPrice = data.lowest;
        this.highestPrice = data.most;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
