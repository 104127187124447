<template>
  <div>
    <Chart :options="options" />
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
export default {
  props: {
    young: {
      type: Array,
      default: () => [],
    },
    student: {
      type: Array,
      default: () => [],
    },
    adult: {
      type: Array,
      default: () => [],
    },
    senior: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Chart,
  },
  computed: {
    options() {
      return {
        chart: {
          type: "column",
          style: {
            fontFamily: "Roboto", //'Open Sans'
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Count (People)",
          },
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} คน</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: "Young",
            data: this.young,
            useHTML: true,
            style: {
              fontFamily: "serif",
            },
          },
          {
            name: "Student",
            data: this.student,
            useHTML: true,
            style: {
              fontFamily: "serif",
            },
          },
          {
            name: "Adult",
            data: this.adult,
            useHTML: true,
            style: {
              fontFamily: "serif",
            },
          },
          {
            name: "Senior",
            data: this.senior,
            useHTML: true,
            style: {
              fontFamily: "serif",
            },
          },
        ],
      };
    },
  },
};
</script>
<style scoped>
@import "https://code.highcharts.com/css/highcharts.css";

.highcharts-color-0 {
  fill: #f45b5b !important;
  stroke: #f45b5b !important;
}

/* Titles */
.highcharts-title {
  fill: black !important;
  font-size: 26px !important;
  font-weight: bold !important;
}
</style>
